<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">实验分组</h3>
    <div class="detail">
      <el-form ref="addForm" size="small" :model="group" class="addForm" label-suffix=":"
               label-width="80px" :disabled="disabled">
        <h3 class="tit">筛选小鼠</h3>
        <el-form-item label="分组类型">
          <el-radio-group v-model="group.type" class="radioGroup" @change="changeType" :disabled="readonly">
            <el-radio :label="1">瘤积范围</el-radio>
            <el-radio :label="0">体重范围</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="group.type==1?'瘤积范围':'体重范围'">
          <el-input v-model="group.min" placeholder="请输入最小值" :readonly="readonly" class="smallWidth">
            <i slot="suffix">{{ group.type == 1 ? 'm3' : 'g' }}</i>
          </el-input>
          <span class="line"></span>
          <el-input v-model="group.max" placeholder="请输入最大值" :readonly="readonly" class="smallWidth">
            <i slot="suffix">{{ group.type == 1 ? 'm3' : 'g' }}</i>
          </el-input>
          <el-button class="submitRange" @click="readonly=false" v-if="readonly">编辑</el-button>
          <el-button class="submitRange" @click="submitRange" v-else>确定</el-button>
        </el-form-item>
        <el-form-item label="小鼠数量" v-if="show">
          <span>{{ group.number }}只</span>
        </el-form-item>
        <template v-if="group.number>0">
          <h3 class="tit">实验分组</h3>
          <template v-if="state==null||state==0||state==3">
            <el-form-item label="实验组">
              <div class="flex groupList">
                <div class="groupExper" :group="ind" v-for="(obj,ind) in group.groupList"
                     :class="{'active':active==ind}"
                     @click="active=ind">
                  <span>实验组{{ numberText[ind] }}</span>
                  <el-button size="small" plain class="delIns" icon="el-icon-close"
                             @click.stop="delIns(obj,ind)"></el-button>
                </div>
                <el-button icon="el-icon-plus" class="addInstru"
                           v-if="group.number>group.groupList.length "
                           @click="addExper">添加实验组
                </el-button>
              </div>
            </el-form-item>
            <template v-if="group.groupList[active]">
              <el-form-item label="分组名称">
                <el-input v-model="group.groupList[active].name" clearable :placeholder='"请输入实验组"+(active+1)+"名称"'
                          class="smallWidth"></el-input>
              </el-form-item>
              <el-form-item label="使用笼舍">
                <div v-for="(obj,ind) in group.groupList[active].groupCageList" :key="ind" class="modeFor">
                  <el-select value-key="id" v-model="obj.cageNo" class="smallWidth" :placeholder='"请输入笼舍"+(ind+1)+"编号"'
                             @change="((val)=>{handleChange(val,obj)})">
                    <el-option
                        v-for="item in cageNo"
                        :key="item.id"
                        :label="item.number"
                        :value="item"></el-option>
                  </el-select>
                  <el-input v-model="obj.num" type="number" :min="0" :max="group.number"
                            :placeholder='"请输入笼舍"+(ind+1)+"小鼠数量"'
                            class="smallWidth"></el-input>
                  <el-button size="small" plain class="delIns" icon="el-icon-close"
                             @click="delCage(group.groupList[active].groupCageList,ind)"></el-button>
                </div>
                <el-button icon="el-icon-plus" class="addInstru smallWidth"
                           @click="addInstru(group.groupList[active].groupCageList)">添加笼舍
                </el-button>
                <el-button v-if="state==0" class="submitGroup" @click="toGroup(group.groupList[active])">实验组{{
                    numberText[active]
                  }}确认
                </el-button>
              </el-form-item>
            </template>
          </template>
          <template v-if="state==1||state==2">
            <el-tabs v-model="nowNumber" class="tabsCage" type="card">
              <el-tab-pane
                  :key="key"
                  v-for="(item,key) in groupObj"
                  :name="key"
              >
                <span slot="label">{{ item[0].groupName }}</span>
                <div class="desc">
                  <p>
                    分组名称：{{ item[0].groupName }}
                  </p>
                  <div class="flex" style="align-items: normal">
                    <label>使用笼舍：</label>
                    <div>
                      <span :key="obj.groupAniId" v-for="obj in item">笼舍编号：{{ obj.cageNo }}</span>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </template>
        </template>
        <template v-if="user.userId != group.reviewer">
          <h3 class="tit">指定复核人</h3>
          <template v-if="state==null||state==0 || state==3">
            <el-form-item label="复核人员">
              <el-radio-group v-model="group.reviewName" class="radioGroup">
                <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                          @click.native="getAppId(item.userId,item.name)"
                > {{ item.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </template>
          <template v-else-if="state==2||state==1">
            <div class="desc">复核人：<span>{{ group.reviewName }}</span></div>
          </template>
        </template>
        <template v-else-if="user.userId == group.reviewer">
          <h3 class="tit">分组提交人</h3>
          <div class="desc"><span>提交人：{{ group.createName }}</span><span>提交时间：{{ group.createTime | formatDay }}</span>
          </div>
        </template>
      </el-form>
      <div class="fromSave">
        <template v-if="state==null ||state==0 || state==3">
          <el-button type="primary" size="small" :disabled="disabled" @click="submitGrouping(1)">分组完成</el-button>
          <!--          <el-button plain type="primary" size="small" :disabled="disabled" @click="submitGrouping(0)">保存</el-button>-->
        </template>

        <el-button plain type="primary" v-if="state!=null" size="small" @click="$emit('changePageGroup',false,0)">
          <template v-if="user.userId == group.reviewer">
            <template v-if="state==2">导出预览</template>
            <template v-else>复核</template>
          </template>
          <template v-else>导出预览</template>
        </el-button>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "grouping",
  props: ['id', 'status'],
  data() {
    return {
      // 笼舍编号
      cageNo: [],
      show: false,
      group: {
        type: 1,
        min: "",
        max: "",
        number: 0,
        reviewName: '',
        reviewer: null,
        groupList: [],
      },
      readonly: false,
      userList: [],
      active: 0,
      numberText: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      groupInfo: {
        name: "",
        count: 0,
        groupCageList: [
          {cageNo: "", num: null}
        ]
      },
      disabled: false,
      state: null,
      // 提交数据
      groupObj: {},
      activeG: 0,
      nowNumber: 0
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    this.getCageNo()
    if (this.status > 25) {
      this.groupExperAct()
    }
    if (this.status > 26) {
      this.disabled = true
    }
  },
  methods: {
    changeType() {
      this.group.min = ''
      this.group.max = ''
      this.show = false
    },
    // 获取笼舍编号
    getCageNo() {
      this.$get('/subject/cage/' + this.id).then(res => {
        if (res.status == 200) {
          this.cageNo = res.data
        }
      })
    },
    // 确定范围
    submitRange() {
      if ($.trim(this.group.min).length === 0) {
        this.$message.warning("最小值不能为空");
        return;
      }
      if ($.trim(this.group.max).length === 0) {
        this.$message.warning("最大值不能为空");
        return;
      }
      let params = {
        type: this.group.type,
        min: this.group.min,
        max: this.group.max,
        subId: this.id
      }
      this.$get('/group/detail/count', params).then(res => {
        if (res.status == 200) {
          this.show = true
          this.group.number = res.data
          this.readonly = true
        }
      })
    },
    // 指定复核人员
    getAppId(id, name) {
      this.group.reviewer = id
      this.group.reviewName = name
    },
    // 添加实验组
    addExper() {
      this.group.groupList.push(this.deepClone4(this.groupInfo))
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
    // 删除实验组
    delIns(obj, index) {
      if (this.state == 0 && obj.id) {
        this.$del('/group/' + obj.id).then(res => {
          if (res.status == 200) {
            this.$message.success("删除成功")
            this.$nextTick(() => {
              this.getGroupInfo()
            })
          }
        })
      } else {
        this.group.groupList.splice(index, 1)
      }
      this.active = 0
    },
    // 添加删除笼舍
    addInstru(groupCageList) {
      groupCageList.push({cageNo: "", num: null})
    },
    delCage(groupCageList, index) {
      groupCageList.splice(index, 1)
    },
    // 选择笼舍
    handleChange(val, obj) {
      obj.cageNo = val.number
      obj.cageId = val.id
    },
    // 获取实验分组信息
    groupExperAct() {
      this.$get('/grouping/' + this.id).then(res => {
        if (res.status == 200) {
          this.state = res.data.grouping.state
          this.group = {
            type: res.data.grouping.type,
            min: res.data.grouping.min,
            max: res.data.grouping.max,
            number: res.data.grouping.number,
            reviewName: res.data.grouping.reviewName,
            reviewer: res.data.grouping.reviewer,
            groupList: [],
            createName: res.data.grouping.createName,
            createTime: res.data.grouping.createTime,
          }
          if (this.state == 0) {
            // 保存
            this.group.groupList = res.data.groupList
          } else {
            // 提交
            let obj = {};
            let arr = []
            res.data.groupAniDetailList.forEach(item => {
              let array = obj[item['groupId']] || [];
              array.push(item);
              obj[item['groupId']] = array;
            });
            this.groupObj = obj
            if (this.nowNumber == 0) {
              for (const key in obj) {
                arr.push(key)
                /* obj[key].forEach(item => {
                 })
                 console.log(obj[key])*/
              }
              this.nowNumber = arr[0]
            }
          }
          this.show = true
        }
      })
    },
    // 提交实验分组
    toGroup(groupExperAct) {
      for (let i = 0; i < groupExperAct.groupCageList.length; i++) {
        let cur = groupExperAct.groupCageList[i].num
        if (!Number.isNaN(parseFloat(cur))) {
          groupExperAct.count += parseFloat(cur)
        }
      }
      groupExperAct.subId = Number(this.id)
      let url, isEdit;
      if (this.state == 0 && groupExperAct.id) {
        isEdit = true
        url = this.$putJson('/group', groupExperAct)
      } else {
        isEdit = false
        url = this.$postJson('/group', groupExperAct)
      }
      url.then(res => {
        if (res.status) {
          this.$message.success(isEdit ? '修改成功' : '新增成功')
          /*if (!isEdit) {
            this.$nextTick(() => {
              this.getGroupInfo()
            })
          }*/
        }
      })
    },
    // 获取实验组信息
    getGroupInfo() {
      this.$get('/group/' + this.id).then(res => {
        if (res.status) {
          this.group.groupList = res.data
        }
      })
    },
    // 提交
    submitGrouping(state) {
      if ($.trim(this.group.min).length === 0) {
        this.$message.warning("最小值不能为空");
        return;
      }
      if ($.trim(this.group.max).length === 0) {
        this.$message.warning("最大值不能为空");
        return;
      }
      if (this.group.number === 0) {
        this.$message.warning("请点击确定筛选小鼠");
        return;
      }
      if ($.trim(this.group.groupList).length === 0) {
        this.$message.warning("实验组不能为空");
        return;
      }
      if ($.trim(this.group.reviewer).length === 0) {
        this.$message.warning("请选择复核人")
        return false;
      }

      // 计算小鼠数量
      let total = null;
      this.group.groupList.forEach(item => {
        item.count = 0
        for (let i = 0; i < item.groupCageList.length; i++) {
          let cur = item.groupCageList[i].num
          if (!Number.isNaN(parseFloat(cur))) {
            item.count += parseFloat(cur)
          }
        }
        if (!Number.isNaN(parseFloat(item.count))) {
          total += parseFloat(item.count)
        }
      })
      if (total > this.group.number) {
        this.$message.warning("小鼠数量超过实际数量")
        return;
      }
      this.group.state = state
      this.group.subId = Number(this.id)
      let url;
      if (this.state == 0) {
        let params = {
          state: state,
          type: this.group.type,
          min: this.group.min,
          max: this.group.max,
          number: this.group.number,
          subId: this.group.subId,
          reviewer: this.group.reviewer,
          reviewName: this.group.reviewName,
        }
        // console.log(params)
        url = this.$putJson('/grouping', params)
      } else {

        url = this.$postJson('/grouping', this.group)
      }
      url.then(res => {
        if (res.status) {
          this.$message.success('提交成功')
          this.$router.go(-1)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
